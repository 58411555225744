export { default as AirplaneSecond } from './Airplane-2.svg';
export { default as Airplane } from './Airplane.svg';
export { default as Archive } from './Archive.svg';
export { default as Arrow } from './Arrow.svg';
export { default as BedDouble } from './BedDouble.svg';
export { default as Bill } from './Bill.svg';
export { default as Calendar } from './Calendar.svg';
export { default as CheckIn } from './CheckIn.svg';
export { default as CheckOut } from './CheckOut.svg';
export { default as CheckRadio } from './CheckRadio.svg';
export { default as Checked } from './Checked.svg';
export { default as ChevronForward } from './ChevronForward.svg';
export { default as Close } from './Close.svg';
export { default as Coffee } from './Coffee.svg';
export { default as Delete } from './Delete.svg';
export { default as Done } from './Done.svg';
export { default as Edit } from './Edit.svg';
export { default as EventMap } from './EventMap.svg';
export { default as Facebook } from './Facebook.svg';
export { default as Filters } from './Filters.svg';
export { default as Fitness } from './Fitness.svg';
export { default as Hotel } from './Hotel.svg';
export { default as Instagram } from './Instagram.svg';
export { default as Logo } from './Logo.svg';
export { default as LogoMobile } from './LogoMobile.svg';
export { default as LogoWhite } from './LogoWhite.svg';
export { default as MapPoint } from './MapPoint.svg';
export { default as Menu } from './Menu.svg';
export { default as Minus } from './Minus.svg';
export { default as Morning } from './Morning.svg';
export { default as Night } from './Night.svg';
export { default as Noon } from './Noon.svg';
export { default as Parking } from './Parking.svg';
export { default as Paypal } from './Paypal.svg';
export { default as Performers } from './Performers.svg';
export { default as Plus } from './Plus.svg';
export { default as Pool } from './Pool.svg';
export { default as Roket } from './Roket.svg';
export { default as Search } from './Search.svg';
export { default as Sleep } from './Sleep.svg';
export { default as Star } from './Star.svg';
export { default as Ticket } from './Ticket.svg';
export { default as Tickets } from './Tickets.svg';
export { default as Twitter } from './Twitter.svg';
export { default as UsdCircle } from './UsdCircle.svg';
export { default as SingleUser } from './User.svg';
export { default as UsersIcon } from './UsersIcon.svg';
export { default as Wifi } from './Wi-fi.svg';
export { default as Youtube } from './Youtube.svg';
export { default as Hide } from './hide.svg';
export { default as Show } from './show.svg';
